<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col class="d-flex justify-content-end align-items-start">
            <action-button
              @click="showBulkExportModal"
              variant="outline-primary"
              class="btn-icon mr-1 ">
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="mr-50"
              /> 
              Export <span v-if="groupsSelected && groupsSelected.length">({{ groupsSelected.length }})</span>
            </action-button>

            <action-button
              class="add-custom-btn mr-50 mb-1"
              variant="primary"
              @click="openAdd"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Custom Group</span>
            </action-button>
            <action-button
              class="add-custom-btn mb-1"
              variant="primary"
              :to="{ name: 'champion-advanced-search', params: { id: defaultProgramId } }"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Filtered Group</span>
            </action-button>
          </b-col>
        </b-row>
        <groups-list
          ref="groupsList"
          @groupsSelected="onChangeGroupsSelected"
        />
        <create-custom-group
          :open="openAddCreateGroup"
          @close="closeSideBar"
          @create="updateList"
        />
      </b-card>
    </b-col>
    <b-modal
      v-model="showExportModal"
      :title="'Export'"
      centered
    >
      <div class="d-flex justify-content-center align-items-center pt-4 pb-4">
          <feather-icon
            v-b-tooltip.hover.top="'Enabling matches to be exported will cause duplicate rows to be created.'"
            icon="InfoIcon"
            size="16"

          /> 

          <b-form-checkbox
            v-model="exportWithMatches"
            value="accepted"
            unchecked-value=""
            class="ml-2"
          >
          Export matches
          </b-form-checkbox>  
      </div>

        <!-- Modal Footer -->
        <template #modal-footer>
          <div class="d-flex w-100 justify-content-center">
            <b-button
              variant="primary"
              centered
            class="mr-2"
              @click="onDownloadGroup('csv')"
            >
              <span >Export as CSV</span>
            </b-button>

            <b-button
              variant="primary"
              right
              @click="onDownloadGroup('xlsx')"
            >
              <span>Export as XLSX</span>
            </b-button>
          </div>
        </template>
      </b-modal>
  </b-row>
</template>
  
<script>
import { VBTooltip, BRow, BCol, BButton, BCard, BDropdown, BFormCheckbox, BDropdownItem } from "bootstrap-vue";
import GroupsList from "./GroupsList.vue";
import { mapGetters } from 'vuex';
import CreateCustomGroup from "./CreateCustomGroup.vue";
import { BE_API_URL } from "@/constants/app";
import ActionButton from "@/views/components/common/ActionButton.vue";
import { programTypes } from "@/models";

export default {
  name: 'Groups',
  components: {
    ActionButton,
    BRow,
    BCol,
    VBTooltip,
    BButton,
    BCard,
    BButton,
    GroupsList,
    CreateCustomGroup,
    BDropdown,
    BFormCheckbox,
    BDropdownItem
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showExportModal: false,
      exportWithMatches: false,
      openAddCreateGroup: false,
      groupsSelected: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    canClickExportButton() {
      return !(this.groupsSelected && this.groupsSelected.length);
    },
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
  methods: {
    openAdd () {
      this.openAddCreateGroup = true;
    },
    closeSideBar () {
      this.openAddCreateGroup = false;
    },
    updateList() {
      this.$refs.groupsList.loadItems();
    },
    onChangeGroupsSelected(groups) {
      this.groupsSelected = groups;
    },
    async onDownloadGroup(type) {
      let groupsSelectedEncode = this.groupsSelected.map(group =>
        encodeURIComponent(JSON.stringify({ id: group.id }))
      );
      let dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/groups/download/${type}` + `?groups=[${groupsSelectedEncode.join(",")}]&with_participant_matches=${this.exportWithMatches ? 1 : 0}`;
      window.document.location = dowloadLink;
    },
    showBulkExportModal() {
      this.showExportModal = true;
    },
    hideBulkExportModal() {
      this.showExportModal = false;
    },
   
  },
};
</script>
